import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  props: {
    imgSrc: String,
  },
  methods: {
    getCropData () {
      const { width, height, x, y } = this.$refs.cropper.getData()
      return { w: Math.floor(width), h: Math.floor(height), x: Math.floor(x), y: Math.floor(y) }
    },
    onloadCropper () {
      this.$refs.cropper.replace(this.imgSrc)
    }
  }
}
